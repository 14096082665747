<template>
  <modal-dialog :modal-title="$t('tutorial-modal.title')">
    <template>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/4sOQILCEmy0?si=jQmtbip1beJLpNeQ"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog.vue'

export default {
  name: 'TutorialModal',
  components: {ModalDialog}
}
</script>
